<template>
  <v-container
    id="container"
    class="fill-height"
    fluid>
    <v-row
      align="center"
      justify="center">
      <v-col
        cols="12"
        sm="8"
        md="4"
        lg="3">
        <v-card
          class="elevation-12"
          :loading="loading">
          <v-toolbar
            color="secondary"
            dark
            flat>
            <v-toolbar-title>{{ $t("actions.login") }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <validation-observer
              ref="validationObserver"
              slim>
              <v-form @submit.prevent="login">
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required"
                  name="username">
                  <v-text-field
                    v-model="form.username"
                    :label="$t('attributes.users.username')"
                    name="username"
                    :error-messages="errors[0]"
                    autofocus
                    type="text"
                    hide-details="auto"
                    @focus="$event.target.select()"
                    @keypress.enter.prevent="$refs.password.focus()">
                    <template #prepend>
                      <font-awesome-icon icon="user" />
                    </template>
                  </v-text-field>
                </ValidationProvider>
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required"
                  name="password">
                  <v-text-field
                    id="password"
                    ref="password"
                    v-model="form.password"
                    name="password"
                    type="password"
                    :label="$t('attributes.users.password')"
                    :error-messages="errors[0]"
                    @focus="$event.target.select()">
                    <template #prepend>
                      <font-awesome-icon icon="lock" />
                    </template>
                  </v-text-field>
                </ValidationProvider>
                <v-btn
                  block
                  class="mt-3"
                  type="submit"
                  color="primary"
                  :disabled="loading"
                  @click="login">
                  {{ $t("actions.login") }}
                </v-btn>
              </v-form>
            </validation-observer>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Validatable from "../../../mixins/Validatable.js";
import ContentLoader from "../../../mixins/ContentLoader.js";
import LoginMixin from "../../../mixins/LoginMixin.js";
import { ValidationObserver } from "vee-validate";

export default {
  name: "DefaultLoginStyle",
  components: {
    ValidationObserver,
  },
  mixins: [Validatable, ContentLoader, LoginMixin],
};
</script>

<style scoped>
#container {
  background-image: url("../../../../public/img/backgrounds/login-background.jpg");
  background-size: 100% 100%;
}

.container.fill-height > .row {
  max-width: none;
}
</style>
