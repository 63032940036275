import { LOGIN_USER as LOGIN_ACTION } from "../store/actions";
import LoginRedirectionHelper from "@/services/LoginRedirectionHelper.js";
import SessionCreateCategory from "@/imported/SessionCreateCategory.json";
export default {
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
    };
  },
  methods: {
    async login() {
      const isValid = await this.$refs.validationObserver.validate();
      if (!isValid) {
        return;
      }
      const {
          $store,
          form,
          $router,
          $route,
          $routes,
          $refs,
          handleErrorResponse,
        } = this,
        { validationObserver } = $refs;

      try {
        this.$startLoading();
        const token = await $store.dispatch(LOGIN_ACTION, {
          form,
          category: SessionCreateCategory.user,
        });
        this.connectWebsocket({ token: `Bearer ${token}` });
        LoginRedirectionHelper.getNextPath($router, $route, $routes, $store);
      } catch (error) {
        console.error(error);
        handleErrorResponse(validationObserver, error);
      } finally {
        this.$finishedLoading();
      }
    },
  }
};
